.button1 {
    background-color: #e3e0e0;
    border: none;
    color: black;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
  }
  
  .b1 {
    text-align: center;
  }
  
  .forminputs {
    border: none;
    text-align: center;
  }
  
  form-control.active .forminputs {
    border: none;
  }
  
  .lead {
    color: #021f12;
  }
  
  .img-fluid {
    height: 100px;
    text-align: center;
  }
  
  .Add-to-cart {
    display: flex;
    width: 150px;
    padding-left: 0px;
    margin-left: 0px;
  }
  
  .total-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  .product-font {
    font-size: 20px;
  }
  
  .Remove {
    padding-left: 0px;
  }
  
  .mobileView {
    display: none;
  }
  
  @media only screen and (max-width: 768px) {
    /* .Add-to-cart{
      width: 50px !important;
      padding-left: 0px !important;
  }
  .sub_child{
      width: 75px;
      height: 75px;
  }
  .Product-info {
      display: flex !important;
      flex-direction: column !important ;
      width: 100px;
  }
  
  .price-ifo{
      display: flex !important;
      flex-direction: column !important;
      width: 100px !important;
  }
  .Remove{
      width: 50px;
  }
  .button1{
      font-size: 10px;
  }
  .total-div{
      overflow-x: scroll !important;
      height: 220px;
  } */
    .total-div {
      overflow-x: scroll !important;
      scrollbar-width: 100%;
    }
  
    .sub_child {
      width: 100%;
      height: 100%;
    }
  
    .Add-to-cart {
      width: 150px;
    }
  
    .Product-info {
      width: 100%;
    }
  
    .price-ifo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  
    .Remove {
      width: 100px;
    }
  
    .Remove .Add-to-cart {
      display: flex !important;
      flex-direction: row !important;
    }
  
    .mobileView {
      display: block;
      border: none;
      background-color: white;
    }
  
    .fa-lg {
      display: none;
    }
  
    .price-margin {
      padding-left: -10px;
      margin-left: -25px;
    }
  }
  
  .form-outline.flex-fill:hover .form-label {
    top: 7px;
    left: 10px;
    font-size: 17px;
    background-color: white;
  }
  
  /* <link href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.4.1/mdb.min.css" rel="stylesheet" /> */
  /* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" /> */
  /* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" /> */
  